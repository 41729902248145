export const COOKIE_TOKEN = "t&p_token";
export const COOKIE_ID = "t&p_id_session";
export const COOKIE_TYPE = "t&p_type";
export const COOKIE_CART_CANDIDATES = "t&p_cart_candidates";

//---DEV
// export const API_URL = "http://localhost:3000";
// export const ORG_CALENDLY_LINK = "https://calendly.com/d/cptb-nxt-jv5/test";

//---PROD
export const API_URL = "https://talpail-api-c979ed09dd3e.herokuapp.com";
export const ORG_CALENDLY_LINK =
  "https://calendly.com/d/ckrj-sh5-kcc/entretien-individuel-coach-talents-paillettes";

//--------- variables googe oauth
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const FB_CLIENT_ID = process.env.REACT_APP_FB_CLIENT_ID;
// export const GOOGLE_CLIENT_ID =
//   "404633393886-em4830rrfb9a8bbmn7ughnosjredt7c5.apps.googleusercontent.com";
// export const GOOGLE_REDIRECT_URL = "http://localhost:3001/login";
export const GOOGLE_REDIRECT_URL = "https://talentsetpaillettes.app/login";

//---------uri
export const logo =
  "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/logo.svg";
// export const logo2 = "/logo192.png";

export const background_footer =
  "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/footer-background.svg";
export const background_profile =
  "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/background_profile.jpeg";
export const background_main_content =
  "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/group-paillettes-bckgnd.svg";

// resume pdf images links
export const pdf_header_bg =
  "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/background-header.jpg";
export const pdf_bg =
  "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/mc-bckgnd.jpg";
export const pdf_title_deco =
  "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/deco-title.jpg";
export const pdf_email_icon =
  "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/email.jpg";
export const pdf_phone_icon =
  "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/telephone.jpg";

// Changer le nom du background_header_cvtheque par background_header car utilisÃ© partout SAUF profile
export const background_header_cvtheque =
  "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/header_background.svg";
export const background_left_pail =
  "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/pail-left.svg";
export const background_right_pail =
  "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/pail-right.svg";
export const femmes_de_bretagne =
  "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/femmes-de-bretagne.png";
export const le_poool =
  "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/le-pool.jpg";

export const paillette =
  "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/paillette.png";
export const coin =
  "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/coin.svg";

// Avatars
export const default_avatar =
  "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/avatar_neutral.svg";

export const avatar_woman_1 =
  "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/woman_1.svg";
export const avatar_woman_2 =
  "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/woman_2.svg";
export const avatar_woman_3 =
  "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/woman_3.svg";
export const avatar_woman_4 =
  "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/woman_4.svg";
export const avatar_woman_5 =
  "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/woman_5.svg";
export const avatar_woman_6 =
  "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/woman_6.svg";
export const avatar_woman_7 =
  "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/woman_7.svg";
export const avatar_woman_8 =
  "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/woman_8.svg";

export const avatar_women = [
  avatar_woman_1,
  avatar_woman_2,
  avatar_woman_3,
  avatar_woman_4,
  avatar_woman_5,
  avatar_woman_6,
  avatar_woman_7,
  avatar_woman_8,
];

export const avatar_man_1 =
  "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/man_1.svg";
export const avatar_man_2 =
  "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/man_2.svg";
export const avatar_man_3 =
  "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/man_3.svg";
export const avatar_man_4 =
  "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/man_4.svg";
export const avatar_man_5 =
  "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/man_5.svg";
export const avatar_man_6 =
  "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/man_6.svg";
export const avatar_man_7 =
  "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/man_7.svg";
export const avatar_man_8 =
  "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/man_8.svg";

export const avatar_men = [
  avatar_man_1,
  avatar_man_2,
  avatar_man_3,
  avatar_man_4,
  avatar_man_5,
  avatar_man_6,
  avatar_man_7,
  avatar_man_8,
];

export const badge_1 =
  "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/badge_1.svg";
export const badge_2 =
  "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/badge_2.svg";
export const badge_3 =
  "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/badge_3.svg";
export const badge_4 =
  "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/badge_4.svg";

export const badges = [badge_1, badge_2, badge_3, badge_4];

export const badge_empty_1 =
  "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/badge_1_empty.svg";
export const badge_empty_2 =
  "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/badge_2_empty.svg";
export const badge_empty_3 =
  "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/badge_3_empty.svg";
export const badge_empty_4 =
  "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/badge_4_empty.svg";

// images des offres
export const gps_dot =
  "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/adresse-du-domicile.png";
export const purchased_order =
  "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/undraw_online_payments_re_y8f2.svg";

// Image validation mdp et compte
export const validation_email =
  "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/undraw_two_factor_authentication_namy.svg";

// images des secteurs d'activitées
export const illust_industries =
  "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/Frame+2610582.png";

const industries_imgs = {
  automobile:
    "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/Secteurs+d'activités/automobile.jpg",
  btp: "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/Secteurs+d'activités/batiment-travaux.jpg",
  trade:
    "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/Secteurs+d'activités/commerce-distribution.jpg",
  career_consellor:
    "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/Secteurs+d'activités/conseil-orientation.jpg",
  energy:
    "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/Secteurs+d'activités/energie.jpg",
  environment:
    "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/Secteurs+d'activités/environnement.jpg",
  finance:
    "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/Secteurs+d'activités/finance.jpg",
  administration:
    "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/Secteurs+d'activités/gestion-administrative.jpg",
  hotel_trade:
    "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/Secteurs+d'activités/hotellerie-restauration.jpg",
  property:
    "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/Secteurs+d'activités/immobilier.jpg",
  factory:
    "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/Secteurs+d'activités/Industries.jpg",
  logistics:
    "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/Secteurs+d'activités/logistique.jpg",
  upkeep:
    "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/Secteurs+d'activités/maintenance-entretien.jpg",
  health:
    "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/Secteurs+d'activités/sante.jpg",
  person_assitance:
    "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/Secteurs+d'activités/service-personne.jpg",
  social:
    "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/Secteurs+d'activités/Social.jpg",
  agriculture:
    "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/harvest-8192037_1280.jpg",
};
// ATTENTION : pas de "_" dans les industries_and_jobs / strengths / social_skills / contract_type -> bug sinon avec l'api
export const industries_and_jobs = [
  {
    reference: "ref - 000017",
    image: industries_imgs.agriculture,
    name: "Agriculture",
    jobs: [
      "Apiculteur",
      "Agriculteur",
      "Aquaculteur",
      "Viticulteur",
      "Arboriculteur",
      "Berger",
      "Bûcheron",
      "Ouvrier forestier",
      "Animateur nature",
      "Conseiller agricole",
      "Conseiller en environnement",
      "Conducteur de machines agricoles",
      "Chargé hygiène sécurité environnement",
      "Éleveur",
      "Technicien Elevage",
      "Horticulteur",
      "Technicien serre",
      "Jardinier",
      "Maraîcher",
      "Pépiniériste",
      "Vendeur-conseil en matériel agricole",
      "Mécanicien en matériel agricole",
      "Technico-commercial en agro-équipement",
    ],
  },
  {
    reference: "ref - 000010",
    image: industries_imgs.automobile,
    name: "Automobile",
    jobs: [
      "Assembleur-monteur mécanique",
      "Carrossier",
      "Chef d'atelier automobile",
      "Commercial en automobiles",
      "Mécanicien automobile",
      "Mécanicien motocycles",
      "Autre (Automobile)",
    ],
  },
  {
    reference: "ref - 00002",
    image: industries_imgs.btp,
    name: "Bâtiment et Travaux Publics",
    jobs: [
      "Agent d'entretien de la voirie",
      "Chef de chantier",
      "Conducteur de travaux",
      "Démolisseur",
      "Grutier",
      "Installateur-poseur de piscines pré-fabriquées",
      "Ouvrier en voiries",
      "Charpentier bois poseur",
      "Charpentier ",
      "Coffreur",
      "Façadier",
      "Maçon",
      "Maçon du paysage",
      "Manoeuvre bâtiment",
      "Monteur de panneaux photovoltaïques",
      "Poseur de façade vitrée",
      "Carreleur",
      "Couvreur",
      "Electricien du bâtiment",
      "Etancheur",
      "Menuisier aluminium",
      "Peintre en bâtiment",
      "Plaquiste",
      "Plâtrier",
      "Plombier Chauffagiste",
      "Poseur de cloisons démontables et mobiles",
      "Poseur de véranda",
      "Serrurier du bâtiment",
      "Sollier-moquettiste",
      "Tailleur de pierres",
      "Technicien en bureau d'études",
      "Autre (Bâtiment et Travaux Publics)",
    ],
  },
  {
    reference: "ref - 00008",
    image: industries_imgs.trade,
    name: "Commerce et Distribution",
    jobs: [
      "Boucher",
      "Boulanger",
      "Brocanteur",
      "Charcutier-traiteur",
      "Epicier",
      "Fleuriste",
      "Opticien",
      "Pâtissier",
      "Poissonnier",
      "Responsable de magasin",
      "Vendeur en animalerie",
      "Vendeur en prêt-à-porter",
      "Administration des ventes",
      "Assistant commercial",
      "Commercial",
      "Technico-commercial",
      "Animateur des ventes",
      "Caissier",
      "Chef de rayon alimentaire",
      "Chef de rayon non alimentaire",
      "Chef de secteur commercial",
      "Employé libre service",
      "Etalagiste",
      "Marchandiseur",
      "Vendeur en équipements de la maison",
      "Autre (Commerce et Distribution)",
    ],
  },
  {
    reference: "ref - 000013",
    image: industries_imgs.career_consellor,
    name: "Conseil, Orientation et Formation",
    jobs: [
      "Conseiller d'orientation",
      "Conseiller en formation",
      "Formateur",
      "Moniteur d'auto-école",
      "Enseignant",
      "Autre (Conseil, Orientation et Formation)",
    ],
  },
  {
    reference: "ref - 000011",
    image: industries_imgs.energy,
    name: "Energie",
    jobs: [
      "Agent de maintenance électrique",
      "Technicien chauffagiste",
      "Technicien énergie, pétrochimie",
      "Autre (Energie)",
    ],
  },
  {
    reference: "ref - 000012",
    image: industries_imgs.environment,
    name: "Environnement",
    jobs: [
      "Agent d'assainissement",
      "Agent d'entretien des espaces naturels",
      "Agent de tri des déchets",
      "Canalisateur",
      "Ripeur",
      "Technicien en risques technologiques",
      "Autre (Environnement)",
    ],
  },
  {
    reference: "ref - 00007",
    image: industries_imgs.finance,
    name: "Finance, banque, assurances",
    jobs: [
      "Chargé de produits en assurance",
      "Chargé d'indemnisation en assurance",
      "Conseiller en assurances",
      "Gestionnaire en assurances, rédacteur en assurances",
      "Agent administratif banque ou assurance",
      "Chargé d'accueil en banque",
      "Gestionnaire/Chargé de clientèle bancaire/assurances",
      "Collaborateur comptable",
      "Assistant comptable",
      "Autre (Finance, banque, assurances)",
    ],
  },
  {
    reference: "ref - 00006",
    image: industries_imgs.administration,
    name: "Gestion administrative et Ressources Humaines",
    jobs: [
      "Agent d'accueil",
      "Assistant administratif",
      "Assistant de direction",
      "Secrétaire",
      "Standardiste",
      "Assistant ressources humaines",
      "Chargé de recrutement",
      "Chargé de communication",
      "Chargé de formation ",
      "Gestionnaire de paie",
      "Chargé de ressources humaines",
      "Autre (Gestion administrative et Ressources Humaines)",
    ],
  },
  {
    reference: "ref - 00003",
    image: industries_imgs.hotel_trade,
    name: "Hôtellerie et Restauration",
    jobs: [
      "Concierge d'hôtel",
      "Employé d'hôtel",
      "Gouvernant d'hôtel",
      "Groom, bagagiste",
      "Réceptionniste en hôtellerie",
      "Valet / femme de chambre",
      "Assistante manager en hôtellerie-restauration",
      "Barman",
      "Chef de cuisine",
      "Commis de cuisine",
      "Cuisinier de collectivité",
      "Employé de restauration collective",
      "Equipier polyvalent de restauration rapide",
      "Maître d'hôtel",
      "Pizzaïolo",
      "Plongeur en restauration",
      "Serveur",
      "Sommelier",
      "Autre (Hôtellerie et Restauration)",
    ],
  },
  {
    reference: "ref - 00001",
    image: industries_imgs.factory,
    name: "Industries",
    jobs: [
      "Agent de traitement de surface",
      "Ajusteur-Monteur",
      "Automaticien",
      "Conducteur d'engins de chantier",
      "Dessinateur projeteur en mécanique",
      "Armaturier de l'industrie béton ",
      "Agent de fabrication du verre ",
      "Dispatcheur",
      "Ebavureur",
      "Extracteur de roches",
      "Foreur",
      "Gestionnaire de flux de production",
      "Modeleur",
      "Mouleur stratifieur",
      "Opérateur de fabrication en matériaux de construction",
      "Opérateur de production en céramique",
      "Paludier",
      "Peintre industriel",
      "Pilote de centrale de béton",
      "Rédacteur technique",
      "Régleur d'équipements industriels",
      "Technicien de l'industrie du verre",
      "Technicien méthodes",
      "Usineur",
      "Agent de conditionnement",
      "Caviste, brasseur",
      "Chef d'équipe en industrie",
      "Conducteur de ligne de conditionnement",
      "Conducteur de ligne en industrie agroalimentaire",
      "Fromager",
      "Opérateur de transformation des viandes",
      "Ouvrier d'abattoir",
      "Assembleur d'ouvrages en bois",
      "Conducteur de machines à bois",
      "Contremaître en menuiserie",
      "Dessinateur de structures en bois",
      "Ebéniste",
      "Machiniste en fabrication de panneaux de bois",
      "Menuisier",
      "Ouvrier de scierie",
      "Cuisinier en industrie",
      "Monteur régleur en plasturgie",
      "Plasturgiste",
      "Technicien de laboratoire en industrie",
      "Assembleur en produits électriques et électroniques",
      "Bobinier",
      "Câbleur",
      "Chef d'atelier production électricité-électronique",
      "Contrôleur technique en électricité-électronique",
      "Dessinateur en électricité-électronique",
      "Monteur-câbleur en électronique",
      "Opérateur sur machines automatisées en production électrique",
      "Technicien études électroniques",
      "Chaudronnier",
      "Chef d'atelier de production en industrie",
      "Cisailleur en métallurgie",
      "Contrôleur technique en métallurgie",
      "Echafaudeur",
      "Forgeron industriel",
      "Métallier",
      "Mouleur noyauteur",
      "Opérateur de traitement thermique",
      "Opérateur en fabrication mécanique ",
      "Soudeur",
      "Technicien en métallurgie",
      "Tuyauteur",
      "Agent de façonnage et routage",
      "Conducteur de machines de façonnage routage",
      "Conducteur de machines d'impression",
      "Correcteur",
      "Opérateur prépresse",
      "Relieur",
      "Reprographe",
      "Technicien des industries graphiques",
      "Blanchisseur industriel",
      "Brodeur",
      "Cardeur en industrie textile",
      "Chapelier",
      "Chef d'équipe d'atelier textile",
      "Coloriste en industrie",
      "Conducteur de machines à tisser",
      "Conducteur de machines de traitement textile",
      "Contrôleur en industrie textile",
      "Cordonnier",
      "Coupeur textile",
      "Couturier",
      "Couturier industriel",
      "Imprimeur",
      "Maroquinier",
      "Mécanicien modèle",
      "Modéliste en industrie",
      "Nappeur",
      "Opérateur de finition en industrie textile",
      "Opérateur de préparation en industrie textile",
      "Opérateur en maroquinerie industrielle",
      "Opératrice en tannerie",
      "Patronnier",
      "Repasseur-presseur",
      "Retoucheur habillement",
      "Autre (Industries)",
    ],
  },
  {
    reference: "ref - 000014",
    image: industries_imgs.property,
    name: "Immobilier",
    jobs: [
      "Chargé de gestion locative en immobilier",
      "Chargé d'opérations immobilières",
      "Conseiller immobilier",
      "Gardien",
      "Autre (Immobilier)",
    ],
  },
  {
    reference: "ref - 00004",
    image: industries_imgs.logistics,
    name: "Logistique et Transport",
    jobs: [
      "Affréteur",
      "Agent de piste",
      "Agent de port de plaisance",
      "Agent de trafic aérien",
      "Agent de transit",
      "Approvisionneur",
      "Cariste",
      "Facteur",
      "Manutentionnaire",
      "Ouvrier portuaire",
      "Préparateur de commandes",
      "Agent de contrôle",
      "Agent d'escale",
      "Agent d'exploitation transport routier de personnes",
      "Aiguilleur du rail",
      "Chauffeur de taxi, VTC",
      "Chauffeur-livreur",
      "Cheminot",
      "Conducteur de bus",
      "Conducteur de poids lourd",
      "Conducteur de train",
      "Exploitant transport routier de marchandises",
      "Matelot de la navigation fluviales",
      "Pontier",
      "Steward / Hôtesse de l'air",
      "Autre (Logistique et Transport)",
    ],
  },

  {
    reference: "ref - 00005",
    image: industries_imgs.upkeep,
    name: "Maintenance, entretien et nettoyage",
    jobs: [
      "Afficheur",
      "Agent de maintenance industrielle",
      "Agent d'entretien des bâtiments",
      "Ascensoriste",
      "Automaticien",
      "Electronicien de maintenance",
      "Frigoriste",
      "Mécanicien agricole",
      "Mécanicien avion",
      "Mécanicien de bâteaux",
      "Préparateur de bâteau",
      "Technicien de maintenance distributeur automatique",
      "Technicien de maintenance en informatique",
      "Technicien de maintenance industrielle",
      "Technicien SAV",
      "Agent de désinsectisation ",
      "Agent d'entretien de locaux ",
      "Cordiste",
      "Jardinier",
      "Laveur de vitres",
      "Autre (Maintenance, entretien et nettoyage)",
    ],
  },
  {
    reference: "ref - 00009",
    image: industries_imgs.health,
    name: "Santé",
    jobs: [
      "Agent de service hospitalier (ASH)",
      "Agent de stérilisation de service hospitalier",
      "Agent thermal",
      "Aide-soignant",
      "Ambulancier",
      "Assistant médical",
      "Auxiliaire de puériculture",
      "Brancardier",
      "Conducteur de ligne en industrie chimique",
      "Infirmier anesthésisite (IADE)",
      "Infirmier de bloc opératoire (IBODE)",
      "Infirmier en puériculture",
      "Infirmier en soins généraux (IDE)",
      "Infirmier scolaire",
      "Préparateur en pharmacie",
      "Sage-femme / Maïeuticien",
      "Secrétaire médical",
      "Technicien de laboratoire médical",
      "Technciien respiratoire",
      "Autre (Santé)",
    ],
  },
  {
    reference: "ref - 000015",
    image: industries_imgs.person_assitance,
    name: "Service à la personne",
    jobs: [
      "Agent funéraire",
      "Aide à domicile",
      "Auxiliaire de Vie Sociale (AVS)",
      "Coiffeur",
      "Conseiller funéraire",
      "Employé de pressing",
      "Esthéticien",
      "Toiletteur",
      "Autre (Service à la personne)",
    ],
  },
  {
    reference: "ref - 000016",
    image: industries_imgs.social,
    name: "Social",
    jobs: [
      "Accompagnateur médico-social",
      "Agent d'accueil social",
      "Animateur petite enfance",
      "Assistant de vie dépendance",
      "Assistant maternel",
      "Educateur de jeunes enfants",
      "Educateur spécialisé",
      "Technicien en Intervention Sociale et Familiale (TISF)",
      "Autre (Social)",
    ],
  },
];

export const strengths = [
  "Créativité, ingéniosité, et originalité",
  "Curiosité et intérêt accordé au monde",
  "Discernement, pensée critique, et ouverture d’esprit",
  "Amour de l’étude, de l’apprentissage",
  "Perspective, Sagesse",
  "Courage et vaillance",
  "Assiduité, application, et persévérance",
  "Honnêteté, intégrité, et sincérité",
  "Joie de vivre, enthousiasme, vigueur et énergie",
  "Capacité d’aimer et d’être aimé(e)",
  "Gentillesse et générosité",
  "Intelligence sociale",
  "Citoyenneté, travail d’équipe et fidélité",
  "Impartialité, équité , et justice",
  "Leadership (capacité à diriger)",
  "Le Pardon",
  "Modestie et humilité",
  "Précaution, prudence, et discrétion",
  "Maîtrise de soi et autorégulation",
  "Reconnaissance de la beauté",
  "Gratitude",
  "Espoir, optimisme, et anticipation du futur",
  "Humour et enjouement",
  "Spiritualité, religiosité, but dans la vie, et foi",
];

export const availability = [
  "Disponible",
  "Indisponible",
  "Disponible à temps partiel",
  "En mission",
];
export const social_skills = [
  "Motivation",
  "Adaptabilité",
  "Capacité à apprendre",
  "Sérieux et impliqué",
  "Autonomie",
  "Travail en équipe",
  "Bonne humeur",
  "Sens de l’effort",
];

export const contract_type = [
  "CDI",
  "CDD",
  "Stage(+2 mois)",
  "Alternance",
  "Apprentissage",
];
