import {
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { getYearsSince2024 } from "../../utils/helpers";

const styles = {
  tHeadCell: {
    borderRight: "1px solid rgba(224, 224, 224, 1)",
    borderBottomWidth: "0px",
    minWidth: "100px !important",
    textAlign: "center",
  },
  tBodyCell: {
    textAlign: "right !important",
    borderBottomWidth: "1px !important",
  },
};

const selectablesYears = getYearsSince2024();

const Turnover = ({
  turnover,
  current_year_turnover,
  selectedYear,
  setSelectedYear,
}) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell colSpan={12}>
              <Typography
                variant="body2"
                sx={{
                  textAlign: "center !important",
                  textDecoration: "underline",
                }}
              >
                Total CA généré par mois et par année
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            {turnover.map((ca, i) => (
              <TableCell sx={styles.tHeadCell} key={`month-${i}`}>
                {ca.month}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {turnover.map((ca, i) => (
              <TableCell
                sx={styles.tBodyCell}
                style={styles.tHeadCell}
                key={`total-${i}`}
              >
                {ca.total}€
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>
              <InputLabel id="selected_turnover_year">Année</InputLabel>
              <Select
                labelId="selected_turnover_year"
                id="selected_year_select"
                value={selectedYear}
                label="Année"
                onChange={(e) => {
                  setSelectedYear(e.target.value);
                }}
              >
                {selectablesYears.map((year, i) => (
                  <MenuItem key={i} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </TableCell>
            <TableCell colSpan={12} align="right">
              Total Année: {current_year_turnover}€
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Turnover;
