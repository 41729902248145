import React, {
  Suspense,
  useEffect,
  Fragment,
  useState,
  useContext,
} from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import Cookies from "js-cookie";
import {
  COOKIE_CART_CANDIDATES,
  COOKIE_ID,
  COOKIE_TYPE,
} from "./config/config";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./utils/theme";
import {
  Login,
  ForgotPassword,
  Profile,
  Resumes,
  Resume,
  Candidate,
  NotFound,
  Register,
  RegisterUser,
  Industries,
  Offers,
  PurchaseCompleted,
  EmailConfirmed,
  ResetPassword,
  Recruiter,
} from "./pages";
import { Alerts, Loader, Layout, FullBackground } from "./components";
import { useFetch } from "./utils/hooks/fetch";
import { UserContext, CartContext } from "./contexts";
import DownloadPdf from "./pages/Profile/Recruiter/resume_pdf";

const initialUserData = {
  id: Cookies.get(COOKIE_ID) || null,
  type: Cookies.get(COOKIE_TYPE) || null,
};

const App = () => {
  const [socialsLogData, setSocialsLogData] = useState({});
  const [user, setUser] = useState(null);
  const [cart, setCart] = useState({
    id: null,
    recruiter_id: null,
    total_price: 0,
    available_credits: 0,
    credits_left: 0,
    total_price_left: 0,
    locked: false,
    payement_method_subscription: "Non défini",
    candidates: JSON.parse(Cookies.get(COOKIE_CART_CANDIDATES) || "[]"),
  });
  const [loading, setLoading] = useState(true);

  const { data, get } = useFetch(true);
  const { data: cartData, get: getCart } = useFetch(true);

  useEffect(() => {
    if (initialUserData.id && initialUserData.type) {
      get(`/${initialUserData.type}/${initialUserData.id}`);
      if (initialUserData.type === "recruiter") {
        getCart(`/cart/${initialUserData.id}`);
      }
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data) {
      setUser(data);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (!user) {
      Cookies.set(COOKIE_CART_CANDIDATES, JSON.stringify(cart?.candidates));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart]);

  useEffect(() => {
    if (cartData && user) {
      setCart(cartData);
    }
  }, [cartData, user]);

  return (
    <Alerts>
      <ThemeProvider theme={theme}>
        <UserContext.Provider value={{ user, setUser }}>
          <CartContext.Provider value={{ cart, setCart }}>
            {loading ? (
              <Loader />
            ) : (
              <Router>
                <Suspense fallback={<Loader />}>
                  <Layout>
                    <Routes>
                      {user && (
                        <Fragment>
                          <Route path="/profile" exact element={<Profile />} />
                          {user.user_type === "user" && (
                            <>
                              <Route
                                path="/candidate/:id"
                                exact
                                element={<Candidate />}
                              />
                              <Route
                                path="/recruiter/:id"
                                exact
                                element={<Recruiter />}
                              />
                            </>
                          )}
                          <Route
                            path="/profile/resume-public"
                            exact
                            element={<Resume />}
                          />
                          <Route
                            path="/candidate/resume-private/:id"
                            exact
                            element={<Resume />}
                          />
                          <Route
                            path="/view-resume/:resumeId"
                            element={<Resume />}
                          />
                          <Route
                            path="/purchase-completed"
                            element={<PurchaseCompleted />}
                          />
                          <Route path="/view_pdf" element={<DownloadPdf />} />
                        </Fragment>
                      )}
                      <Route path="/offers" element={<Offers />} />
                      <Route path="/industries" element={<Industries />} />
                      <Route path="/" element={<Industries />} />
                      <Route path="/resumes" element={<Resumes />} />
                      <Route path="/resumes/:id" element={<Resume />} />
                      <Route
                        path="/reset-password"
                        element={<ResetPassword />}
                      />
                      <Route
                        path="/login"
                        exact
                        element={
                          <RedirectProvider>
                            <Login setSocialsLogData={setSocialsLogData} />
                          </RedirectProvider>
                        }
                      />
                      <Route
                        path="/signup-taipai"
                        element={
                          <RedirectProvider>
                            <RegisterUser />
                          </RedirectProvider>
                        }
                      />
                      <Route
                        path="/signup"
                        element={
                          <RedirectProvider>
                            <Register socialsLogData={socialsLogData} />
                          </RedirectProvider>
                        }
                      />
                      <Route
                        path="/forgot-password"
                        element={
                          <RedirectProvider>
                            <ForgotPassword />
                          </RedirectProvider>
                        }
                      />
                      <Route
                        path="/email_confirmation"
                        element={
                          <RedirectProvider>
                            <EmailConfirmed />
                          </RedirectProvider>
                        }
                      />
                      <Route
                        path="/resend_email_confirmation"
                        element={
                          <RedirectProvider>
                            <EmailConfirmed />
                          </RedirectProvider>
                        }
                      />
                      <Route path="*" element={<NotFound />} />
                    </Routes>
                  </Layout>
                </Suspense>
              </Router>
            )}
          </CartContext.Provider>
        </UserContext.Provider>
      </ThemeProvider>
    </Alerts>
  );
};

/**
 * Empêche d'acceder à la page children et de rediriger vers la page profil, dans le cas où on est connecté
 *
 * @param {ReactElement} children - Composant react qu'on veut interdire l'accès
 * @returns {ReactElement} - Composant react
 */
const RedirectProvider = ({ children }) => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate("/profile");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  return <FullBackground>{!user && children}</FullBackground>;
};

export default App;
